import Sky from './sky';

const modeButtons = Array.from(document.getElementsByName('mode'));
let sky = new Sky();

function getCorrectMode() {
  let value;
  for (let i = 0; i < modeButtons.length; i++) {
    if (modeButtons[i].checked) {
      value = modeButtons[i].value;
      if (modeButtons[i].value === 'day') {
        document.body.classList = '';
        sky.destroy();
      } else if (modeButtons[i].value === 'night') {
        document.body.classList.add('night');
        sky.destroy();
      } else {
        sky.init();
      }
    }
  }
  localStorage.setItem('theme', value);
}
const theme = localStorage.getItem('theme');
if (theme && theme !== 'undefined') {
  for (let i = 0; i < modeButtons.length; i++) {
    if (modeButtons[i].value === theme) {
      modeButtons[i].checked = true;
    } else {
      modeButtons[i].checked = false;
    }
  }
}
getCorrectMode();
for (let i = 0; i < modeButtons.length; i++) {
  modeButtons[i].addEventListener('change', (e) => {
    getCorrectMode();
  });
}
