import * as Astronomy from 'astronomy-engine/astronomy.min.js';
import { scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';

const DEFAULT_LAT = 52.537552;
const DEFAULT_LNG = -2.80062;

const BODIES = [
  {
    name: 'Sun',
    fill: '#fff4aa',
    r: 30
  },
  {
    name: 'Moon',
    fill: '#f1f1f1',
    r: 30
  }
];

class Sky {
  constructor() {
    this.latitude = DEFAULT_LAT;
    this.longitude = DEFAULT_LNG;
    this.azScale;
    this.altScale;
    this.observer;
    this.elevation = 0;
    this.svg;
    this.date;
  }

  getPosition(body) {
    let equ_ofdate = Astronomy.Equator(
      body.name,
      this.date,
      this.observer,
      true,
      true
    );
    let hor = Astronomy.Horizon(
      this.date,
      this.observer,
      equ_ofdate.ra,
      equ_ofdate.dec,
      'normal'
    );
    return {
      screenHorizontal: this.azScale(hor.azimuth),
      screenVertical: this.altScale(hor.altitude)
    };
  }

  getMoonPath(screenHorizontal, screenVertical, r) {
    let phase = Astronomy.MoonPhase(this.date);
    const screenHPx = (screenHorizontal / 100) * window.innerWidth;
    const screenVPx = (screenVertical / 100) * window.innerHeight;
    if (phase <= 180) {
      return `M ${screenHPx} ${screenVPx - r}a ${r} ${r} 0 0 1 0 ${2 * r}a ${
        r * (1 - phase / 90)
      } ${r} 0 0 ${phase > 90 ? 1 : 0} 0 ${-2 * r}z`;
    } else {
      return `M ${screenHPx} ${screenVPx - r}a ${r} ${r} 0 0 0 0 ${2 * r}a ${
        r * ((phase - 180) / 90 - 1)
      } ${r} 0 0 ${phase > 270 ? 1 : 0} 0 ${-2 * r}z`;
    }
  }

  getPositions() {
    for (let body of BODIES) {
      let { screenHorizontal, screenVertical } = this.getPosition(body);

      if (body.name === 'Moon') {
        let moon = this.svg.append('g').attr('id', 'moon-container');
        moon
          .append('path')
          .attr('id', 'moon-path')
          .attr('fill', body.fill)
          .attr('d', () =>
            this.getMoonPath(screenHorizontal, screenVertical, body.r)
          );
        moon
          .append('circle')
          .attr('id', 'moon')
          .attr('r', body.r)
          .attr('cx', `${screenHorizontal}%`)
          .attr('cy', `${screenVertical}%`)
          .attr('stroke', '#ffffff')
          .attr('stroke-opacity', 0.6)
          .attr('stroke-width', 1)
          .attr('fill', 'none');
      } else {
        this.svg
          .append('circle')
          .attr('id', body.name)
          .attr('cx', `${screenHorizontal}%`)
          .attr('cy', `${screenVertical}%`)
          .attr('r', body.r)
          .attr('fill', body.fill);
        if (screenVertical > 0 && screenVertical < 100) {
          select('body').attr('class', 'day');
        } else {
          select('body').attr('class', 'night');
        }
      }
    }
  }

  updatePositions() {
    this.date = new Date();
    for (let body of BODIES) {
      const { screenHorizontal, screenVertical } = this.getPosition(body);
      this.svg
        .select(`#${body.name}`)
        .attr('cx', `${screenHorizontal}%`)
        .attr('cy', `${screenVertical}%`);

      if (body.name === 'Moon') {
        this.svg
          .select('#moon-path')
          .attr('d', () =>
            this.getMoonPath(screenHorizontal, screenVertical, body.r)
          );
      } else {
        if (screenVertical > 0 && screenVertical < 100) {
          select('body').attr('class', 'day has-loaded');
        } else {
          select('body').attr('class', 'night has-loaded');
        }
      }
    }
    // console.log('positions updated');
  }

  destroy() {
    if (this.observer) {
      delete this.observer;
      clearInterval(this.interval);
      this.svg.remove();
    }
  }

  startSky() {
    this.observer = new Astronomy.Observer(
      this.latitude,
      this.longitude,
      this.elevation
    );
    this.date = new Date();
    this.azScale = scaleLinear()
      .domain([0, 90, 180, 270, 360])
      .range([50, 100, 50, 0, 50]);
    this.altScale = scaleLinear().domain([0, 90]).range([100, 0]);
    this.svg = select('main')
      .insert('svg', ':first-child')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('preserveAspectRatio', 'none');
    this.getPositions();
    this.interval = setInterval(() => {
      this.updatePositions();
    }, 60000);
    let timeout = false;
    window.addEventListener('resize', () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.updatePositions();
      }, 250);
    });
  }

  init() {
    // Try getting users lat and lng
    // else use defaults
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.startSky();
        },
        () => {
          this.startSky();
        }
      );
    } else {
      this.startSky();
    }
  }
}

export default Sky;
